import styled from 'styled-components'

const CalcGroupRow = styled.div`
    display: grid;
    grid-template-columns: ${(props: { cols: number }) =>
        `repeat(${props.cols}, 1fr)`};
    gap: 10px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
    }
`

const CalcGroup = styled.div`
    margin-bottom: 32px;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media (max-width: 561px) {
        margin-bottom: 16px;
    }

    ${(props: { disabled?: boolean }) =>
        props.disabled &&
        `
        opacity: 0.5;
        pointer-events: none;
  `}
`

const CalcGroupTitle = styled.div`
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @media (max-width: 1100px) {
        font-size: 14px;
        margin-bottom: 10px;
    }

    @media (max-width: 561px) {
        flex-direction: column;
    }
`

const CalcSubGroup = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: max-content 80px 120px;
    gap: 16px;
    height: 42px;
    margin-top: 32px;

    @media (max-width: 1100px) {
        margin-top: 16px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        height: auto;

        & > div:first-of-type {
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }
`

const CalcGroupRowWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;
    }

    @media (max-width: 561px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const CalcLink = styled.button`
    font-weight: 300;
    font-size: 14px;
    color: var(--accent-color);
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.3;
    }

    @media (max-width: 1100px) {
        font-size: 12px;
    }

    img {
        margin-right: 12px;
    }
`

export {
    CalcGroupRow,
    CalcGroup,
    CalcGroupTitle,
    CalcSubGroup,
    CalcGroupRowWrap,
    CalcLink,
}
