import styled from 'styled-components'

const Container = styled.div`
    color: #fff;
    background-color: var(--accent-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 24px;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 16px;

    @media (max-width: 1100px) {
        font-size: 16px;
        margin-bottom: 10px;
    }
`

const Price = styled.div`
    font-weight: 700;
    font-size: 60px;
    display: flex;
    align-items: flex-start;

    @media (max-width: 1100px) {
        font-size: 32px;
    }
`

const Notification = styled.div`
    font-size: 32px;
    font-weight: 700;
`

const Currency = styled.div`
    font-weight: 600;
    font-size: 32px;
    margin-left: 10px;
    opacity: 0.6;

    @media (max-width: 1100px) {
        font-size: 16px;
    }
`

const Submit = styled.button`
    padding: 28px;
    font-size: 20px;
    color: #000;
    background-color: #fff;
    border-radius: 5px;

    width: 100%;
    border: none;
    margin-top: 32px;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }

    @media (max-width: 1100px) {
        font-size: 14px;
        padding: 12px;
        margin-top: 16px;
    }
`

const Wrap = styled.div`
    max-width: 265px;
    width: 100%;
`

const NoItem = styled.button`
    color: #fff;
    background: transparent;
    border: none;
    display: block;
    margin: auto;
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
`

interface TotalsProps {
    price: number
    onOrder: Function
    showForm: boolean
    onlyByRequest: boolean
    city: string
    destination: string
    onEmptyOrder: Function
}

const Totals = ({
    price,
    onOrder,
    showForm,
    onlyByRequest,
    city,
    destination,
    onEmptyOrder
}: TotalsProps) => {
    return (
        <Container>
            <Wrap>
                <Title>Итого:</Title>
                {!onlyByRequest && (
                    <Price>
                        {price.toLocaleString()} <Currency>руб</Currency>
                    </Price>
                )}
                {onlyByRequest && <Notification>По запросу</Notification>}
                {!showForm && (
                    <>
                        <Submit
                            disabled={!city && !destination}
                            onClick={() => onOrder && onOrder()}
                        >
                            Заказать
                        </Submit>

                        <NoItem onClick={() => onEmptyOrder && onEmptyOrder()}>
                            Не нашел нужный предмет
                        </NoItem>
                    </>
                )}
            </Wrap>
        </Container>
    )
}

export default Totals
