import { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

const SwitchboxGroup = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`

const SwitchboxControl = styled.input`
    opacity: 0;
    appearance: none;
    z-index: -999;
    position: absolute;

    &:checked {
        ~ label {
            span {
                filter: none;
                transition: 350ms;
                &::before {
                    left: calc(100% - 20px);
                    transition: 350ms;
                }
            }
        }
    }

    &:disabled {
        ~ label {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
`

const SwitchboxLabel = styled.label`
    position: relative;
    font-weight: 300;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;

    @media (max-width: 1100px) {
        font-size: 14px;
    }
`

const SwitchboxToggle = styled.span`
    margin-left: 8px;
    width: 37px;
    height: 22px;
    background-color: var(--accent-color);
    border-radius: 100px;
    position: relative;
    filter: grayscale(100);
    transition: 350ms;

    &::before {
        content: '';
        position: absolute;
        left: 2px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 50%;
        transition: 350ms;
    }
`

interface SwitchboxProps {
    name?: string
    label: string
    onChange: Function
    checked?: boolean
    disabled?: boolean
}

const Switchbox = ({
    name,
    label,
    onChange,
    checked,
    disabled,
}: SwitchboxProps) => {
    const [id] = useState<string>(uuid())

    const handleChange = () => {
        onChange && onChange(label)
    }

    return (
        <SwitchboxGroup>
            <SwitchboxControl
                id={id}
                type="checkbox"
                name={name}
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
            />
            <SwitchboxLabel htmlFor={id}>
                {label}
                <SwitchboxToggle />
            </SwitchboxLabel>
        </SwitchboxGroup>
    )
}

export default Switchbox
