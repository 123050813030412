import { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

const CheckboxGroup = styled.div`
    position: relative;
`

const CheckboxControl = styled.input`
    opacity: 0;
    appearance: none;
    z-index: -999;
    position: absolute;

    &:checked {
        ~ label {
            &::before {
                background-color: var(--accent-color);
                transition: 350ms;
            }

            &::after {
                opacity: 1;
                transition: 350ms;
            }
        }
    }
`

const CheckboxLabel = styled.label`
    position: relative;
    font-weight: 300;
    padding-left: 34px;
    cursor: pointer;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        font-size: 14px;
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 22px;
        height: 22px;
        border: 1px solid var(--accent-color);
        background-color: #fff;
        transition: 350ms;
    }

    &::after {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6668 1.5L5.5001 10.6667L1.33344 6.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 22px;
        height: 22px;
        position: absolute;
        left: 2px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-repeat: no-repeat;
        background-size: 16px 12px;
        background-position: center;
        opacity: 0;
        transition: 350ms;
    }
`

interface CheckboxProps {
    name?: string
    label: string
    onChange: Function
    checked?: boolean
}

const Checkbox = ({ name, label, onChange, checked }: CheckboxProps) => {
    const [id] = useState<string>(uuid())

    const handleChange = (e: { target: HTMLInputElement }) => {
        onChange && onChange(e.target.checked)
    }

    return (
        <CheckboxGroup>
            <CheckboxControl
                id={id}
                type="checkbox"
                name={name}
                onChange={handleChange}
                checked={checked}
            />
            <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>
        </CheckboxGroup>
    )
}

export default Checkbox