import { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'



const url =
    'http://placehold.jp/F5F5F5/ffffff/120x120.jpg?text=%20&css=%7B%22border-radius%22%3A%2215px%22%7D'

const ListItemLabel = styled.label`
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    padding: 16px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 350ms;
    height: 100%;
    box-sizing: border-box;
`

const ListItem = styled.li`
    height: 100%;
    min-width: 1px;
`

const ListItemImg = styled.img`
    margin: auto;
    margin-bottom: 12px;
    width: 100%;
    height: 70px;
    object-fit: contain;
`

const ListItemTitle = styled.span`
    font-size: 12px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ListItemControl = styled.input`
    opacity: 0;
    appearance: none;
    z-index: -999;
    position: absolute;

    &:checked {
        ~ label {
            border: 1px solid var(--accent-color);
            transition: 350ms;
        }
    }
`
interface AddItemProps {
    item: {
        title: string
        img?: string
    }
    onChecked: Function
    checked: boolean
}

const AddItem = ({ item, onChecked, checked }: AddItemProps) => {
    const [id] = useState<string>(uuid())
    return (
        <>
            <ListItem>
                <ListItemControl checked={checked} id={id} type="checkbox" name="item" onChange={()=>onChecked && onChecked(item)} />
                <ListItemLabel htmlFor={id}>

                    <ListItemTitle>{item.title}</ListItemTitle>
                </ListItemLabel>
            </ListItem>
        </>
    )
}

export default AddItem
