import styled from 'styled-components'

import CheckboxRadio from '../form/CheckboxRadioBtn'

import office from '../../assets/img/office.svg'
import home from '../../assets/img/home.svg'
import { useState } from 'react'

const Wrap = styled.div`
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background-color: #f5f5f5;

    @media (max-width: 768px) {
        padding: 24px 12px;
    }
`

const Heading = styled.div`
    font-size: 32px;
    margin-bottom: 16px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 18px;
    }
`

const Subheading = styled.div`
    font-weight: 300;
    margin-bottom: 48px;
`

const Row = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    width: 100%;
    max-width: 812px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        gap: 16px;
    }
`

const TypeSelection = styled(CheckboxRadio)`
    height: 155px;
    width: 100%;
`

const Button = styled.button`
    background-color: var(--accent-color);
    padding: 20px;
    color: #fff;
    border: none;
    width: 100%;
    max-width: 240px;
    border-radius: 5px;
    cursor: pointer;
`

interface IntroProps {
    onTypeSelect: Function
}

const Intro = ({ onTypeSelect }: IntroProps) => {
    const [type, setType] = useState<string>('')

    const handleTypeSelect = () => {
        onTypeSelect && onTypeSelect(type)
    }

    return (
        <Wrap>
            <Heading>Калькулятор переезда</Heading>
            <Subheading>Расчет стоимости</Subheading>
            <Row>
                <TypeSelection
                    type="radio"
                    name="type"
                    icon={office}
                    label="Офисный переезд"
                    onChange={setType}
                />
                <TypeSelection
                    type="radio"
                    name="type"
                    icon={home}
                    label="Домашний переезд"
                    onChange={setType}
                />
            </Row>
            <Button onClick={handleTypeSelect}>Далее</Button>
        </Wrap>
    )
}

export default Intro
