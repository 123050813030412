import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

import Input from '../form/Input'
import Textarea from '../form/Textarea'
import List from './List/List'

import { getItemsMaterials, calcWorkers } from '../../utility/calc'

const Wrap = styled.div`
    padding: 40px 64px;

    @media (max-width: 768px) {
        padding: 16px 24px;
    }
`

const Heading = styled.div`
    font-size: 24px;
    margin-bottom: 24px;
`

const Subheading = styled.div`
    font-weight: 300;
    margin-bottom: 24px;
`

const ControlGroup = styled.div`
    margin-bottom: 16px;
`

const Foot = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: 561px) {
        grid-template-columns: 1fr;
    }
`

const ListLink = styled.button`
    display: block;
    font-size: 14px;
    margin-bottom: 16px;
    color: var(--accent-color);
    background-color: transparent;
    border: none;
    padding: 0;
`

const Btn = styled.button`
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: 561px) {
        padding: 10px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    ${(props: { outline?: boolean }) =>
        props.outline && 'background: transparent; color: #000;'}
`

const NotificationWrap = styled.div`
    font-size: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

interface FormProps {
    onCancel: Function
    items: Array<any>
    additionals: Array<string>
    price: number
    volume: number
    city: string
    destination: string
    emptyOrder: boolean
}

const Form = ({
    onCancel,
    items,
    additionals,
    price,
    volume,
    city,
    destination,
    emptyOrder,
}: FormProps) => {
    const [name, setName] = useState<string>('')
    const [tel, setTel] = useState<string>('')
    const [comment, setComment] = useState<string>('')
    const [showList, setShowList] = useState<boolean>(false)
    const [wrapHeight, setWrapHeight] = useState<number | string>('auto')
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [notification, setNotification] = useState<string>(
        'Спасибо! Ваша заявка отправлена'
    )

    const packagingMaterials = getItemsMaterials(items)
    const workers = calcWorkers(items, additionals, volume)

    const wrapEl = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const el = wrapEl.current as HTMLDivElement
        setTimeout(() => {
            if (el) setWrapHeight(el.clientHeight)
        })
    }, [])

    const submit = async () => {
        const data = new FormData()
        const materialsJSON = JSON.stringify(packagingMaterials)

        data.append('name', name)
        data.append('tel', tel)
        data.append('comment', comment)

        if (emptyOrder) {
            data.append('emptyOrder', '1')
        }

        data.append('price', price.toString())
        data.append('volume', volume.toString())
        data.append('city', city.toString())
        data.append('destination', destination.toString())
        data.append('workers', workers.toString())

        if (additionals.length) {
            const additionalsJSON = JSON.stringify(additionals)

            data.append('addittionals', additionalsJSON)
        }

        if (items.length) {
            const itemsInfo = items.map(
                (el: { title: string; qty: number }) => {
                    const { title, qty } = el
                    return {
                        title,
                        qty,
                    }
                }
            )
            const itemsJSON = JSON.stringify(itemsInfo)

            data.append('items', itemsJSON)
        }

        if (additionals.includes('Упаковать')) {
            data.append('materials', materialsJSON)
        }

        const response = await fetch('https://calc.mobi-mobi.ru/form.php', {
            method: 'POST',
            body: data,
        })

        console.log(response)

        if (Boolean(await response.text())) {
            setSubmitted(true)
            setNotification('Спасибо! Ваша заявка отправлена')
        } else {
            alert('Произошла ошибка. Попробуйте еще раз')
        }
    }

    return (
        <Wrap>
            <div ref={wrapEl} style={{ height: wrapHeight }}>
                {!submitted ? (
                    <>
                        {!showList ? (
                            <>
                                <ListLink onClick={() => setShowList(true)}>
                                    Проверить список вещей
                                </ListLink>
                                <Heading>Контакты</Heading>
                                <Subheading>
                                    Оставьте свои контакты и наш менеджер
                                    свяжется с вами
                                </Subheading>

                                <ControlGroup>
                                    <Input
                                        onInput={setName}
                                        type="text"
                                        label="Ваше имя"
                                        value={name}
                                        showLabel
                                    />
                                </ControlGroup>
                                <ControlGroup>
                                    <Input
                                        onInput={setTel}
                                        type="tel"
                                        label="Номер телефона"
                                        value={tel}
                                        showLabel
                                    />
                                </ControlGroup>
                                <ControlGroup>
                                    <Textarea
                                        onInput={setComment}
                                        label="Комментарий к заказу"
                                        value={comment}
                                        showLabel
                                    />
                                </ControlGroup>

                                <Foot>
                                    <Btn
                                        outline
                                        onClick={() => onCancel && onCancel()}
                                    >
                                        Вернуться назад
                                    </Btn>
                                    <Btn
                                        disabled={tel.length < 18 || !name}
                                        onClick={submit}
                                    >
                                        Оформить заказ
                                    </Btn>
                                </Foot>
                            </>
                        ) : (
                            <List
                                simple={true}
                                items={items}
                                onAdd={() => null}
                                onItemQtyChange={() => null}
                                onItemAdditionalsChange={() => null}
                                onCancel={() => setShowList(false)}
                                onListClose={() => setShowList(false)}
                            />
                        )}
                    </>
                ) : (
                    <NotificationWrap>{notification}</NotificationWrap>
                )}
            </div>
        </Wrap>
    )
}

export default Form
