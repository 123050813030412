import { useState } from 'react'
import styled from 'styled-components'

import { CalcGroupRow, CalcGroup, CalcGroupTitle } from '../Elements'
import Input from '../../form/Input'
import ListItem from './ListItem'
import AddList from '../Add/AddList'

import furniture from '../../../assets/img/furniture.png'
import electronics from '../../../assets/img/electronics.svg'
import dishes from '../../../assets/img/dishes.svg'

import type { ItemProps } from '../../../utility/calc'

const AddButton = styled.button`
    background: #ffffff;
    border-radius: 8px;
    padding: 14px 30px;
    transition: 350ms;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;

    img {
        margin-right: 8px;
    }

    @media (max-width: 561px) {
        padding: 10px;
    }
`

const CalcGroupRowActions = styled(CalcGroupRow)`
    @media (max-width: 561px) {
        grid-template-columns: 1fr;
    }
`

const ListContainer = styled.div`
    background-color: #fff;
    height: 266px;
    display: flex;
    border-radius: 8px;
`

const ListWrap = styled.ul`
    list-style: none;
    padding: 0;
    margin: auto 0;
    width: calc(100% - 4px);
    height: calc(100% - 8px);
    overflow: auto;

    &::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--accent-color);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
    }
`

const ListFoot = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: 561px) {
        grid-template-columns: 1fr;
    }
`

const ListBtn = styled.button`
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: 561px) {
        padding: 10px;
    }

    ${(props: { outline?: boolean }) =>
        props.outline && 'background: transparent; color: #000;'}
`

interface ListProps {
    onCancel: Function
    onAdd: Function
    items: Array<ItemProps>
    simple?: boolean
    onItemQtyChange: Function
    onListClose: Function
    onItemAdditionalsChange: Function
}

const List = ({
    onCancel,
    onAdd,
    items,
    simple,
    onItemQtyChange,
    onListClose,
    onItemAdditionalsChange
}: ListProps) => {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [showAddItem, setShowAddItem] = useState<boolean>(false)
    const [category, setCategory] = useState<string>('')

    const queryItems = items.filter((item) => {
        if (searchQuery as string) {
            return (
                item.title.substring(0, searchQuery.length).toLowerCase() ===
                searchQuery.toLowerCase()
            )
        } else {
            return true
        }
    })

    const handleAddItem = (cat: string) => {
        setCategory(cat)
        setShowAddItem(true)
    }

    const handleAddItems = (newItems: Array<ItemProps>) => {
        onAdd && onAdd(newItems)
        setCategory('')
        setShowAddItem(false)
    }

    return (
        <>
            {!showAddItem && (
                <>
                    <CalcGroup>
                        <CalcGroupTitle>
                            Поиск мебели/техники/иного
                        </CalcGroupTitle>

                        <Input
                            label="Введите название"
                            type="text"
                            onInput={setSearchQuery}
                            value={searchQuery}
                        />
                    </CalcGroup>

                    {!simple && (
                        <>
                            <CalcGroup>
                                <CalcGroupRowActions cols={3}>
                                    <AddButton
                                        onClick={() =>
                                            handleAddItem('furniture')
                                        }
                                    >
                                        <img src={furniture} alt="" />
                                        Добавить мебель
                                    </AddButton>
                                    <AddButton
                                        onClick={() =>
                                            handleAddItem('electronics')
                                        }
                                    >
                                        <img src={electronics} alt="" />
                                        Добавить технику
                                    </AddButton>
                                    <AddButton
                                        onClick={() => handleAddItem('etc')}
                                    >
                                        <img src={dishes} alt="" />
                                        Добавить прочее
                                    </AddButton>
                                </CalcGroupRowActions>
                            </CalcGroup>
                        </>
                    )}

                    <ListContainer>
                        <ListWrap>
                            {queryItems.map((item: ItemProps) => (
                                <ListItem
                                    simple={simple}
                                    key={item.title}
                                    item={item}
                                    onQtyChange={(qty: number) =>
                                        onItemQtyChange(item, qty)
                                    }
                                    onAdditionalsChange={(additionals: Array<string>) => onItemAdditionalsChange(item, additionals)}
                                />
                            ))}
                        </ListWrap>
                    </ListContainer>

                    <ListFoot>
                        <ListBtn outline onClick={() => onCancel && onCancel()}>
                            Вернуться назад
                        </ListBtn>
                        {!simple && (
                            <ListBtn onClick={() => onListClose()}>
                                Добавить список
                            </ListBtn>
                        )}
                    </ListFoot>
                </>
            )}
            {showAddItem && (
                <AddList
                    onCancel={() => setShowAddItem(false)}
                    onAdd={handleAddItems}
                    category={category}
                    currentItems={items}
                />
            )}
        </>
    )
}

export default List
