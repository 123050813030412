import styled from 'styled-components'

import AddItem from './AddItem'
import { CalcGroup } from '../Elements'
import Input from '../../form/Input'


import type { ItemProps } from '../../../utility/calc'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const Title = styled.div`
    font-size: 18px;
    margin-bottom: 16px;
`

const ListContainer = styled.div`
    background-color: #fff;
    height: 390px;
    display: flex;
    padding: 24px;
    padding-right: 0;
    border-radius: 8px;

    @media (max-width: 561px) {
        padding: 10px;
    }
`

const ListWrap = styled.ul`
    list-style: none;
    padding: 0;
    margin: auto 0;
    width: calc(100% - 14px);
    height: calc(100% - 8px);
    padding-right: 8px;
    overflow: auto;
    display: grid;
    gap: 12px;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(4,1fr);
    align-items: stretch;

    @media (max-width: 768px) {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 561px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(2, 1fr);
        width: calc(100% - 8px);
    }

    &::-webkit-scrollbar {
        width: 3px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--accent-color);
        border-radius: 8px;
    }
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: transparent;
    }
`

const ListFoot = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: 561px) {
        grid-template-columns: 1fr;
    }
`

const ListBtn = styled.button`
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent-color);
    border: 1px solid var(--accent-color);
    color: #fff;
    border-radius: 5px;
    cursor: pointer;

    @media (max-width: 561px) {
        padding: 10px;
    }

    ${(props: { outline?: boolean }) =>
        props.outline && 'background: transparent; color: #000;'}
`

interface ListProps {
    onCancel: Function
    onAdd: Function
    category: string
    currentItems: Array<ItemProps>
}

const AddList = ({ onCancel, onAdd, category, currentItems }: ListProps) => {
    const [selectedItems, setSelectedItems] = useState<Array<ItemProps>>([
        ...currentItems,
    ])

    const [searchQuery, setSearchQuery] = useState<string>('')

    const { roomItems } = useSelector((state: any) => state.data)

    const handleItemChecked = (item: ItemProps) => {
        let newItems: Array<ItemProps> = []

        if (selectedItems.some((el) => el.title === item.title)) {
            newItems = selectedItems.filter((el) => el.title !== item.title)
        } else {
            newItems = [...selectedItems]
            newItems.push(item)
        }

        setSelectedItems(newItems)
    }

    const itemsCategory = roomItems
        .filter((el: { type: string }) => el.type === category)
        .filter((el: { title: string }) => {
            if (searchQuery as string) {
                return (
                    el.title.substring(0, searchQuery.length).toLowerCase() ===
                    searchQuery.toLowerCase()
                )
            } else {
                return true
            }
        }) as Array<ItemProps>
        


    return (
        <>
            <Title>Добавление предмета</Title>

            <CalcGroup>
                <Input
                    label="Введите название"
                    type="text"
                    onInput={setSearchQuery}
                    value={searchQuery}
                />
            </CalcGroup>

            <ListContainer>
                <ListWrap>
                    {itemsCategory.map((item) => (
                        <AddItem
                            onChecked={handleItemChecked}
                            key={item.title}
                            item={item}
                            checked={selectedItems.some(
                                (el) => el.title === item.title
                            )}
                        />
                    ))}
                </ListWrap>
            </ListContainer>

            <ListFoot>
                <ListBtn outline onClick={() => onCancel && onCancel()}>
                    Вернуться назад
                </ListBtn>
                <ListBtn onClick={() => onAdd(selectedItems)}>Добавить</ListBtn>
            </ListFoot>
        </>
    )
}

export default AddList
