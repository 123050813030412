import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import IMask from 'imask'

const Input = styled.input`
    color: #000;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    width: 100%;
    font-family: MuseoSansCyrl;
    transition: 350ms;
    font-weight: 300;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }

    @media (max-width: 500px) {
        padding: 10px 20px;
    }

    ${(props: { error: boolean }) =>
        props.error &&
        `
    border: 1px solid #e3000f;
  `}
`

const InputGroup = styled.div`
    width: 100%;
`

const Label = styled.label`
    font-weight: 300;
    margin-bottom: 8px;
    display: block;

    ${(props: { showLabel: boolean }) =>
        !props.showLabel &&
        `
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
  `}
`

interface InputProps {
    label: string
    value: string | number
    onInput: Function
    error?: boolean
    type: string
    min?: number
    className?: string
    showLabel?: boolean
    required?: boolean
}

const InputComponent = ({
    value,
    onInput,
    error,
    label,
    type,
    min,
    className,
    showLabel,
    required,
}: InputProps) => {
    const [id] = useState<string>(uuid())

    const inputEl = useRef<HTMLInputElement>(null)

    const inputHandler = (val: string) => {
        onInput && onInput(val)
    }

    useEffect(() => {
        const el = inputEl.current as HTMLInputElement
        if (inputEl) {
            if (type === 'tel') {
                IMask(el, {
                    mask: '+{7} (000) 000-00-00',
                })
            }
        }
    }, [])



    return (
        <InputGroup>
            <Label htmlFor={id} showLabel={showLabel || false}>
                {label}
            </Label>
            <Input
                ref={inputEl}
                id={id}
                className={className}
                type={type}
                placeholder={label}
                value={value}
                onInput={(event) =>
                    inputHandler((event.target as HTMLInputElement).value)
                }
                error={error || false}
                min={min}
                required={required}
            />
        </InputGroup>
    )
}

export default InputComponent
