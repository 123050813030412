import { useState, useEffect } from 'react'
import styled from 'styled-components'

import Group from './Group'
import Input from './Input'

import reverse from '../../assets/img/reverse.svg'

const Suggestions = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    z-index: 100;
`

const SuggestionsList = styled.ul`
    list-style: none;
    padding: 0 !important;
    margin: 0;
`

const SuggestionsItem = styled.li`
    padding: 10px;
    cursor: pointer;
    transition: 350ms;
    font-family: MuseoSansCyrl;
    font-weight: 500;
    font-size: 13.3333px;

    &:hover {
        background-color: #eeeeee;
        transition: 350ms;
    }
`

const ReverseBtn = styled.button`
    position: absolute;
    right: 20px;
    background: transparent;
    top: 0;
    bottom: 0;
    margin: auto;
    border: none;
    cursor: pointer;
`

interface ComboboxProps {
    options: Array<string>
    label: string
    onInput: Function
    value: string
    error: boolean
    canReverse?: boolean
    onReverse?: Function
}

const Combobox = ({
    options,
    label,
    onInput,
    value,
    error,
    canReverse,
    onReverse,
}: ComboboxProps) => {
    const [showDropdown, setShowDropdown] = useState(false)

    const suggestions = options.filter((option) => {
        if (value) {
            return (
                option.substring(0, value.length).toLowerCase() ===
                value.toLowerCase()
            )
        } else {
            return true
        }
    })

    const inputHandler = (val: string) => {
        onInput && onInput(val)
    }

    useEffect(() => {
        if (options) {
            if (value.length && value !== suggestions[0]) {
                setShowDropdown(true)
            } else {
                setShowDropdown(false)
            }
        }
    }, [value])

    return (
        <Group>
            <Input
                label={label}
                value={value}
                onInput={inputHandler}
                error={error}
                type="text"
            />
            <>
                {canReverse && (
                    <ReverseBtn onClick={() => onReverse && onReverse()}>
                        <img src={reverse} alt="" />
                    </ReverseBtn>
                )}
            </>

            <>
                {Boolean(suggestions.length) && showDropdown && (
                    <Suggestions>
                        <SuggestionsList>
                            {suggestions.map((option) => (
                                <SuggestionsItem
                                    onClick={() => inputHandler(option)}
                                    key={option}
                                >
                                    {option}
                                </SuggestionsItem>
                            ))}
                        </SuggestionsList>
                    </Suggestions>
                )}
            </>
        </Group>
    )
}

export default Combobox
