import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Office from './Types/Office'
import Home from './Types/Home'
import CalcTotals from './Totals'
import CalcForm from './Form'

import {
    calcHumanResourcesByVolume,
    calcTotalVolume,
    calcTotalPackagingPrice,
    calcHumanResourcesByItem,
} from '../../utility/calc'

import type { ItemProps } from '../../utility/calc'

const Wrap = styled.div`
    display: grid;
    grid-template-columns: auto 390px;
    gap: 20px;
    overflow: hidden;
    border-radius: 8px;
    background-color: #f5f5f5;

    ${(props: { showForm: boolean }) =>
        props.showForm &&
        `
        grid-template-columns: 390px auto;
    `}

    @media (max-width: 1100px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

const CalcContent = styled.div`
    padding: 32px 40px;

    @media (max-width: 1100px) {
        padding: 16px 24px;
    }
`

interface CalcProps {
    type: string
}

const CalcType = (props: any) => {
    if (props.type === 'Офисный переезд') {
        return <Office {...props} />
    } else {
        return <Home {...props} />
    }
}

const Calc = ({ type }: CalcProps) => {
    const [showForm, setShowForm] = useState<boolean>(false)

    const [city, setCity] = useState<string>('')
    const [destination, setDestination] = useState<string>('')

    const [items, setItems] = useState<Array<ItemProps>>([])

    const [additionals, setAdditionals] = useState<Array<string>>([])

    const [complexMethod, setComplexMethod] = useState<boolean>(false)
    const [customValue, setCustomValue] = useState<number>(0)

    const [result, setResult] = useState<number>(0)
    const [onlyByRequest, setOnlyByRequest] = useState<boolean>(false)

    const [calculatedVolume, setCalculatedVolume] = useState<number>(0)
    const [emptyOrder, setEmptyOrder] = useState<boolean>(false)

    const { cities, routes } = useSelector((state: any) => state.data)

    const handleEmptyOrder = () => {
        setEmptyOrder(true)
        setShowForm(true)
    }

    const handleCancel = () => {
        setEmptyOrder(false)
        setShowForm(false)
    }

    useEffect(() => {
        // Find packaging price by items
        let totalVolume = calcTotalVolume(items)
        let totalPackagingPrice: number = additionals.includes('Упаковать')
            ? calcTotalPackagingPrice(items)
            : 0
        let humanResourcesPrice: number = 0

        // Reset only by request
        setOnlyByRequest(false)

        // If volume custom value is set
        if (customValue > 0) totalVolume = customValue

        // If total volume is zero return zero price
        if (totalVolume === 0) {
            setResult(0)
            return
        }

        // If total volume is more than 30 - show "by request" text
        if (totalVolume > 30) {
            setOnlyByRequest(true)
            return
        }

        setCalculatedVolume(totalVolume)

        // if no additionals = no workhours
        if (!additionals.length) {
            humanResourcesPrice = 0
        } else if (!complexMethod) {
            // Find human resources price by simple method (per volume)
            humanResourcesPrice = calcHumanResourcesByVolume(
                totalVolume,
                additionals
            )
        } else {
            // or complex method (per item)
            humanResourcesPrice = calcHumanResourcesByItem(items, additionals)
        }

        // if has city, destination
        if ((city.length, destination.length)) {
            var selectedDestination, price
            // Find starting city in routes
            const selectedCity = routes.find((el:any) => el.city === city)

            // If has starting city in routes
            if (selectedCity) {
                // Find destination city in routes
                selectedDestination = selectedCity.destinations.find(
                    (city:any) => city.title === destination
                )
            }

            // If has destination city in routes
            if (selectedDestination) {
                // Find closest weight grade
                var selectedWeight = selectedDestination.prices.find(
                    (el:any) => totalVolume <= el.size
                )

                // If has closest weight - get moving price
                if (selectedWeight) price = selectedWeight.price
            }

            // If has all data calc total moving price
            if (selectedCity && selectedDestination && price)
                setResult(price + totalPackagingPrice + humanResourcesPrice)
            else setOnlyByRequest(true)
        }
    }, [city, destination, additionals, items, customValue])

    return (
        <Wrap showForm={showForm}>
            {!showForm && (
                <CalcContent>
                    <CalcType
                        type={type}
                        cities={cities}
                        city={city}
                        destination={destination}
                        additionals={additionals}
                        onCityInput={setCity}
                        onDestinationInput={setDestination}
                        onItemsChange={setItems}
                        onAdditionalsChange={setAdditionals}
                        onComplexMethodChange={setComplexMethod}
                        onSetCustomValue={setCustomValue}
                    />
                </CalcContent>
            )}
            <CalcTotals
                city={city}
                destination={destination}
                showForm={showForm}
                price={result}
                onlyByRequest={onlyByRequest}
                onOrder={() => setShowForm(true)}
                onEmptyOrder={() => handleEmptyOrder()}
            />

            {showForm && (
                <CalcForm
                    items={items}
                    price={result}
                    volume={calculatedVolume}
                    city={city}
                    emptyOrder={emptyOrder}
                    destination={destination}
                    additionals={additionals}
                    onCancel={() => handleCancel()}
                />
            )}
        </Wrap>
    )
}

export default Calc
