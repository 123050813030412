import { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

const CheckboxRadioGroup = styled.div`
    position: relative;
    width: 100%;
    border-radius: 8px;
`

const CheckboxRadioLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    background: #ffffff;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: 8px;
    cursor: pointer;
    transition: 350ms;
    font-weight: 300;
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;

    @media (max-width: 1100px) {
        font-size: 12px;
    }

    @media (max-width: 768px) {
        padding: 10px 0;
    }
`

const CheckboxRadioControl = styled.input`
    opacity: 0;
    appearance: none;
    z-index: -999;
    position: absolute;

    &:checked {
        ~ label {
            border: 1px solid var(--accent-color);
            transition: 350ms;
        }
    }

    &:disabled {
        ~ label {
            cursor: not-allowed;
            opacity: 0.3;
        }
    }
`

const CheckboxRadioImg = styled.img`
    display: block;
    max-height: 70%;
    margin-bottom: 12px;
`

const CheckboxRadioLink = styled.button`
    cursor: pointer;
    border: none;
    background: transparent;
    font-weight: 300;
    font-size: 12px;
    margin-top: 8px;
    color: var(--accent-color);
    text-align: center;
    text-decoration: underline;
    width: 100%;
    opacity: 0;
    transition: 350ms;

    @media (max-width: 768px) {
        display: none;
    }

    ${(props: { checked: boolean }) =>
        props.checked &&
        `
        opacity: 1;

        @media (max-width: 768px) {
            display: block;
        }
    `}
`

interface RadioProps {
    name: string
    label: string
    icon?: string
    className?: string
    onChange: Function
    type: string
    hasLink?: boolean
    checked?: boolean
    onClick?: Function
    disabled?: boolean
}

const Radio = ({
    name,
    icon,
    label,
    className,
    onChange,
    type,
    hasLink,
    checked,
    onClick,
    disabled,
}: RadioProps) => {
    const [id] = useState<string>(uuid())

    const handleChange = (e: { target: HTMLInputElement }) => {
        onChange && onChange(e.target.value)
    }

    return (
        <CheckboxRadioGroup className={className}>
            <CheckboxRadioControl
                disabled={disabled}
                id={id}
                value={label}
                type={type}
                name={name}
                onChange={handleChange}
                checked={checked}
            />
            <CheckboxRadioLabel htmlFor={id}>
                {icon && <CheckboxRadioImg src={icon} />}
                {label}
            </CheckboxRadioLabel>

            {hasLink && (
                <CheckboxRadioLink
                    onClick={() => onClick && onClick()}
                    checked={checked || false}
                >
                    Уточнить список
                </CheckboxRadioLink>
            )}
        </CheckboxRadioGroup>
    )
}

export default Radio
