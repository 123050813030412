import { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

const Textarea = styled.textarea`
    color: #000;
    border: 1px solid #dbdbdb;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    width: 100%;
    font-family: MuseoSansCyrl;
    transition: 350ms;
    font-weight: 300;
    resize: none;
    box-sizing: border-box;

    &:focus {
        outline: none;
    }

    @media (max-width: 500px) {
        padding: 10px 20px;
    }

    ${(props: { error: boolean }) =>
        props.error &&
        `
    border: 1px solid #e3000f;
  `}
`

const TextareaGroup = styled.div`
    width: 100%;
`

const Label = styled.label`
    font-weight: 300;
    margin-bottom: 8px;
    display: block;

    ${(props: { showLabel: boolean }) =>
        !props.showLabel &&
        `
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      border: 0;
  `}
`

interface TextareaProps {
    label: string
    value: string | number
    onInput: Function
    error?: boolean
    min?: number
    className?: string
    showLabel?: boolean
}

const TextareaComponent = ({
    value,
    onInput,
    error,
    label,
    className,
    showLabel
}: TextareaProps) => {
    const [id] = useState<string>(uuid())

    const inputHandler = (val: string) => {
        onInput && onInput(val)
    }

    return (
        <TextareaGroup>
            <Label htmlFor={id} showLabel={showLabel || false}>{label}</Label>
            <Textarea
                id={id}
                className={className}
                placeholder={label}
                value={value}
                onInput={(event) =>
                    inputHandler((event.target as HTMLInputElement).value)
                }
                error={error || false}
            />
        </TextareaGroup>
    )
}

export default TextareaComponent
