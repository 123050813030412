import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import Intro from './components/calc/Intro'
import Calc from './components/calc/Calc'
import { CalcLink } from './components/calc/Elements'

import {
    setItems,
    setHomeRooms,
    setOfficeRooms,
    setCities,
    setHumanResources,
    setPackaging,
    setRoutes,
} from './store/data/dataSlice'

import arrow from './assets/img/arrow.svg'

const Container = styled.div`
    max-width: 1200px;
    width: 95%;
    position: relative;
`

const BackLink = styled(CalcLink)`
    position: static;
    margin: auto;
    width: max-content;
    margin-bottom: 30px;
`

const App = () => {
    const [type, setType] = useState<string>('')
    const dispatch = useDispatch()

    useEffect(() => {
        fetch('/data/items.json')
            .then((response) => response.json())
            .then((data) => dispatch(setItems(data)))

        fetch('/data/cities.json')
            .then((response) => response.json())
            .then((data) => dispatch(setCities(data)))

        fetch('/data/home-rooms.json')
            .then((response) => response.json())
            .then((data) => dispatch(setHomeRooms(data)))

        fetch('/data/human-resources.json')
            .then((response) => response.json())
            .then((data) => dispatch(setHumanResources(data)))

        fetch('/data/office-rooms.json')
            .then((response) => response.json())
            .then((data) => dispatch(setOfficeRooms(data)))

        fetch('/data/packaging.json')
            .then((response) => response.json())
            .then((data) => dispatch(setPackaging(data)))

        fetch('/data/routes.json')
            .then((response) => response.json())
            .then((data) => dispatch(setRoutes(data)))
    }, [])

 

    return (
        <Container>
            {!type && <Intro onTypeSelect={setType} />}
            {type && (
                <>
                    <BackLink onClick={() => setType('')}>
                        <img src={arrow} alt="" />
                        Вернуться к выбору типа переезда
                    </BackLink>
                    <Calc type={type} />
                </>
            )}
        </Container>
    )
}

export default App
