import { store } from '../store/store'

let humanResources: any
let packagingList: any

const listener = () => {
    let data = store.getState().data
    humanResources = data.humanResources
    packagingList = data.packaging
}

store.subscribe(listener)




interface PackagingProps {
    title: string
    qty: number
}

interface ItemProps {
    title: string
    size?: string
    base?: boolean
    packaging?: Array<PackagingProps>
    volume: number
    qty: number
    type: string
    additionals: Array<string>
    disassembly: number
    assembly: number
    loading: number
    unloading: number
    packing: number
    unpacking: number
}

interface HumanResources {
    volume: number
    workhours: {
        base: number
        full: number
    }
    workers: {
        base: number
        full: number
    }
}

interface Materials {
    qty: number
    title: string
}

const calcHumanResourcesByVolume = (
    volume: number,
    additionals: Array<string>
) => {
    // Create array of volume grades
    const volumeGrades = humanResources.map((el:any) => el.volume)
    // Find closest grade
    const closestVolumeGrade = volumeGrades.find((el:any) => {
        return volume <= el
    })

    let hours: number = 0
    let workers: number = 0

    let basePriceByHour: number = 600
    let basePriceByHourWithElevator: number = 500

    if (volume === 0) {
        return 0
    }

    // Find grade data
    const range = humanResources.find(
        (el:any) => el.volume === closestVolumeGrade
    ) as HumanResources

    // If user ordered packaging / unpackaging
    if (
        additionals.includes('Упаковать') ||
        additionals.includes('Распаковать')
    ) {
        workers = range?.workers.full
    } else {
        workers = range?.workers.base
    }

    // If user ordered packaging / unpackaging or assembly/dissambly
    if (
        additionals.includes('Упаковать') ||
        additionals.includes('Распаковать') ||
        additionals.includes('Собрать') ||
        additionals.includes('Разобрать')
    ) {
        hours = range?.workhours.full
    } else {
        hours = range?.workhours.base
    }

    // If user ordered load AND unload - multiply hours by two
    if (
        additionals.includes('Загрузить') &&
        additionals.includes('Выгрузить')
    ) {
        hours *= 2
    }

    // Min 4 hours

    if (hours < 4 && hours > 0) hours = 4

    // Split hours by two cities
    const loadHours: number = hours / 2
    const unloadHours: number = hours / 2
    let totalPerHour: number = 0

    // if has elevator at load
    if (additionals.includes('Лифт загрузки')) {
        totalPerHour += loadHours * basePriceByHourWithElevator
    } else {
        totalPerHour += loadHours * basePriceByHour
    }

    // if has elevator at unload
    if (additionals.includes('Лифт выгрузки')) {
        totalPerHour += unloadHours * basePriceByHourWithElevator
    } else {
        totalPerHour += unloadHours * basePriceByHour
    }

    return totalPerHour * workers
}

const calcHumanResourcesByItem = (
    items: Array<ItemProps>,
    additionals: Array<string>
) => {
    const hasLoadAndUnload =
        additionals.includes('Загрузить') && additionals.includes('Выгрузить')

    let hours: number = 0
    let total: number = 0
    let basePriceByHour: number = 600
    let basePriceByHourWithElevator: number = 500

    items.forEach((item) => {
        const itemAdditionals = item.additionals

        // Check what item additionals selected
        if (itemAdditionals.includes('Разобрать')) {
            hours += item.disassembly * item.qty
        }
        if (itemAdditionals.includes('Собрать')) {
            hours += item.assembly * item.qty
        }
        if (itemAdditionals.includes('Упаковать')) {
            hours += item.packing * item.qty
        }
        if (itemAdditionals.includes('Распаковать')) {
            hours += item.unpacking * item.qty
        }

        // Check if user selected loading or unloading
        if (additionals.includes('Загрузить')) {
            hours += item.loading * item.qty
        }
        if (additionals.includes('Выгрузить')) {
            hours += item.unloading * item.qty
        }

        // Check if user selected additinals globally

        if (additionals.includes('Разобрать')) {
            hours += item.disassembly * item.qty
        }
        if (additionals.includes('Собрать')) {
            hours += item.assembly * item.qty
        }
        if (additionals.includes('Упаковать')) {
            hours += item.packing * item.qty
        }
        if (additionals.includes('Распаковать')) {
            hours += item.unpacking * item.qty
        }
    })

    if (hasLoadAndUnload) {
        hours *= 2
    }

    hours = Math.ceil(hours)

    // Min 4 hours
    if (hours < 4 && hours > 0) hours = 4

    const loadHours = hours / 2
    const unloadHours = hours / 2

    // if has elevator at load
    if (additionals.includes('Лифт загрузки')) {
        total += loadHours * basePriceByHourWithElevator
    } else {
        total += loadHours * basePriceByHour
    }

    // if has elevator at unload
    if (additionals.includes('Лифт выгрузки')) {
        total += unloadHours * basePriceByHourWithElevator
    } else {
        total += unloadHours * basePriceByHour
    }

    return total
}

const calcWorkers = (
    items: Array<ItemProps>,
    additionals: Array<string>,
    customVolume: number
) => {
    const volumeGrades = humanResources.map((el:any) => el.volume)

    let workers: number = 0
    let volume: number = 0

    if (!items.length) {
        volume = customVolume
    } else {
        items.forEach((item) => {
            const itemVolume = item.volume * item.qty
            volume += itemVolume
        })
    }

    const closestVolumeGrade = volumeGrades.find((el:any) => {
        return volume <= el
    })

    // Find grade data
    const range = humanResources.find(
        (el:any) => el.volume === closestVolumeGrade
    ) as HumanResources

    // If user ordered packaging / unpackaging
    if (
        additionals.includes('Упаковать') ||
        additionals.includes('Распаковать')
    ) {
        workers = range?.workers.full
    } else {
        workers = range?.workers.base
    }

    return workers
}

const calcTotalPackagingPrice = (items: Array<ItemProps>) => {
    let totalPackagingPrice = 0
    let materials = new Map()

    // Foreach item in furniture packaging
    items.forEach((item) => {
        // Find total materials
        if (item.packaging?.length) {
            item.packaging.forEach((packaging) => {
                // if already has material in map
                if (materials.has(packaging.title)) {
                    const qty = materials.get(packaging.title)
                    materials.set(packaging.title, qty + packaging.qty)
                    // or add new
                } else {
                    materials.set(packaging.title, packaging.qty)
                }
            })
        }
    })

    const packagingMaterials = [] as Array<Materials>

    // Create new array with ceiled values
    materials.forEach((qty: number, material: string) => {
        let formattedQty = Math.ceil(qty)

        if (
            material === 'картон гофрированный, двухслойный' ||
            material === 'пленка воздушно-пузырьковая, шир 1,2 м'
        ) {
            formattedQty = Math.ceil(qty / 10) * 10
        } else {
            formattedQty = Math.ceil(qty)
        }

        packagingMaterials.push({
            title: material,
            qty: formattedQty,
        })
    })

    // Calc total materials price
    packagingMaterials.forEach((material: Materials) => {
        const packagingPrice =
            (packagingList.find((el:any) => el.title === material.title)
                ?.price as number) || 0

        totalPackagingPrice += packagingPrice * material.qty
    })

    return totalPackagingPrice
}

const calcTotalVolume = (items: Array<ItemProps>) => {
    let totalVolume = 0

    // Foreach item in furnite  and total packaging
    items.forEach((item) => {
        // Find total volume
        if (item.volume) totalVolume += item.volume * (item.qty || 1)
    })

    // Ceil volume value
    totalVolume = Math.ceil(totalVolume)

    return totalVolume
}

const getItemsMaterials = (items: Array<ItemProps>) => {
    let materials = new Map()

    // Foreach item in furniture packaging
    items.forEach((item) => {
        // Find total materials
        if (item.packaging?.length) {
            item.packaging.forEach((packaging) => {
                // if already has material in map
                if (materials.has(packaging.title)) {
                    const qty = materials.get(packaging.title)
                    materials.set(packaging.title, qty + packaging.qty)
                    // or add new
                } else {
                    materials.set(packaging.title, packaging.qty)
                }
            })
        }
    })

    const packagingMaterials = [] as Array<Materials>

    // Create new array with ceiled values
    materials.forEach((qty: number, material: string) => {
        let formattedQty = Math.ceil(qty)

        if (
            material === 'картон гофрированный, двухслойный' ||
            material === 'пленка воздушно-пузырьковая, шир 1,2 м'
        ) {
            formattedQty = Math.ceil(qty / 10) * 10
        } else {
            formattedQty = Math.ceil(qty)
        }

        packagingMaterials.push({
            title: material,
            qty: formattedQty,
        })
    })

    return packagingMaterials
}

export {
    calcWorkers,
    calcHumanResourcesByVolume,
    calcTotalPackagingPrice,
    calcTotalVolume,
    calcHumanResourcesByItem,
    getItemsMaterials,
}
export type { ItemProps }
