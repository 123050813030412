import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface dataState {
    roomItems: Array<object>
    homeRooms: Array<object>
    officeRooms: Array<object>
    cities: Array<string>
    humanResources: Array<object>
    packaging: Array<object>
    routes: Array<object>
}

const initialState: dataState = {
    roomItems: [],
    homeRooms: [],
    officeRooms: [],
    cities: [],
    humanResources: [],
    packaging: [],
    routes: [],
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setItems(state: any, action: PayloadAction<Array<object>>) {
            state.roomItems = action.payload
        },
        setHomeRooms(state: any, action: PayloadAction<Array<object>>) {
            state.homeRooms = action.payload
        },
        setCities(state: any, action: PayloadAction<Array<string>>) {
            state.cities = action.payload
        },
        setHumanResources(state: any, action: PayloadAction<Array<object>>) {
            state.humanResources = action.payload
        },
        setOfficeRooms(state: any, action: PayloadAction<Array<object>>) {
            state.officeRooms = action.payload
        },
        setPackaging(state: any, action: PayloadAction<Array<object>>) {
            state.packaging = action.payload
        },
        setRoutes(state: any, action: PayloadAction<Array<object>>) {
            state.routes = action.payload
        },
    },
})

export const {
    setItems,
    setHomeRooms,
    setOfficeRooms,
    setCities,
    setHumanResources,
    setPackaging,
    setRoutes,
} = dataSlice.actions

export default dataSlice.reducer
