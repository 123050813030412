import { useState } from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

const CheckboxBtnGroup = styled.div`
    position: relative;
`

const CheckboxBtnLabel = styled.label`
    background: #ffffff;
    border-radius: 8px;
    padding: 14px 12px;
    font-weight: 300;
    transition: 350ms;
    width: auto;
    display: block;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        font-size: 14px;
        padding: 8px;
    }
`

const CheckboxBtnControl = styled.input`
    opacity: 0;
    appearance: none;
    z-index: -999;
    position: absolute;

    &:checked {
        ~ label {
            border: 1px solid var(--accent-color);
            background-color: var(--accent-color);
            transition: 350ms;
            color: #fff;
        }
    }

    &:disabled {
        ~ label {
            cursor: not-allowed;
            opacity: 0.3;
        }
    }
`

interface CheckboxBtnProps {
    name: string
    label: string
    onChange: Function
    className?: string
    disabled?: boolean
    checked?: boolean
}

const CheckboxBtn = ({
    name,
    label,
    onChange,
    className,
    disabled,
    checked
}: CheckboxBtnProps) => {
    const [id] = useState<string>(uuid())

    const handleChange = () => {
        onChange && onChange(label)
    }

    return (
        <CheckboxBtnGroup className={className}>
            <CheckboxBtnControl
                id={id}
                type="checkbox"
                name={name}
                onChange={handleChange}
                disabled={disabled}
                checked={checked}
            />
            <CheckboxBtnLabel htmlFor={id}>{label}</CheckboxBtnLabel>
        </CheckboxBtnGroup>
    )
}

export default CheckboxBtn
