import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Combobox from '../../form/Combobox'
import Checkbox from '../../form/Checkbox'
import Input from '../../form/Input'
import CheckboxRadio from '../../form/CheckboxRadioBtn'
import CheckboxBtn from '../../form/CheckboxBtn'
import Switchbox from '../../form/Switchbox'
import List from '../List/List'

import {
    CalcGroupRow,
    CalcGroup,
    CalcGroupTitle,
    CalcSubGroup,
    CalcGroupRowWrap,
    CalcLink,
} from '../Elements'

import kitchen from '../../../assets/img/kitchen.svg'
import livingroom from '../../../assets/img/livingroom.svg'
import bedroom from '../../../assets/img/bedroom.svg'
import childroom from '../../../assets/img/babyroom.svg'
import bathroom from '../../../assets/img/bathroom.svg'
import cabinet from '../../../assets/img/cabinet.svg'
import plus from '../../../assets/img/plus.svg'

import type { ItemProps } from '../../../utility/calc'


const WorkplacesInput = styled(Input)`
    padding: 12px 5px;
    text-align: center;
`

const CalcGroupRowMain = styled(CalcGroupRow)`
    @media (max-width: 561px) {
        grid-template-columns: repeat(1, 1fr);
    }
`

interface CalcProps {
    cities: Array<string>
    city: string
    destination: string
    onCityInput: Function
    onDestinationInput: Function
    onItemsChange: Function
    onSetCustomValue: Function
    additionals: Array<string>
    onAdditionalsChange: Function
    onComplexMethodChange: Function
}

const Home = ({
    cities,
    city,
    destination,
    onCityInput,
    onDestinationInput,
    onItemsChange,
    onSetCustomValue,
    additionals,
    onAdditionalsChange,
    onComplexMethodChange,
}: CalcProps) => {
    const [error, setError] = useState<boolean>(false)

    const [customVolume, setCustomVolume] = useState<boolean>(false)
    const [customVolumeNumber, setCustomVolumeNumber] = useState<number>(0)

    const [rooms, setRooms] = useState<Array<string>>([])

    const [showList, setShowList] = useState<boolean>(false)
    const [items, setItems] = useState<Array<ItemProps>>([])

    const [customList, setCustomList] = useState<boolean>(false)
    const [customListItems, setCustomListItems] = useState<Array<ItemProps>>([])

    const { homeRooms, roomItems } = useSelector((state: any) => state.data)

    const handleCityInput = (val: string) => {
        onCityInput && onCityInput(val)
    }

    const handleDestinationInput = (val: string) => {
        onDestinationInput && onDestinationInput(val)
    }

    const handleRooms = (val: string) => {
        let newRooms: Array<string>
        if (rooms.includes(val)) {
            newRooms = rooms.filter((el) => el !== val)
        } else {
            newRooms = [...rooms]
            newRooms.push(val)
        }
        setRooms(newRooms)
    }

    const handleAdditionals = (val: string) => {
        let newAdditionals: Array<string>
        if (additionals.includes(val)) {
            newAdditionals = additionals.filter((el) => el !== val)
        } else {
            newAdditionals = [...additionals]
            newAdditionals.push(val)
        }

        if (!newAdditionals.includes('Загрузить')) {
            newAdditionals = newAdditionals.filter((el) => el !== 'Лифт загрузки')
        }

        if (!newAdditionals.includes('Выгрузить')) {
            newAdditionals = newAdditionals.filter((el) => el !== 'Лифт выгрузки')
        }


        onAdditionalsChange && onAdditionalsChange(newAdditionals)
    }

    const handleReverse = () => {
        const currentCity = city
        const currentDest = destination
        handleCityInput(currentDest)
        handleDestinationInput(currentCity)
    }

    const handleAddList = () => {
        setCustomList(true)
        setShowList(true)
    }

    const isMobile = () => {
        return window.matchMedia('(max-width: 561px)').matches
    }

    useEffect(() => {
        onComplexMethodChange(false)
        let itemsArray: Array<ItemProps> = []

        if (rooms.length) {
            onComplexMethodChange(true)

            const itemsList = new Map()

            rooms.forEach((room) => {
                const currentRoom = homeRooms.find((el:any) => el.title === room)

                currentRoom?.items.forEach((item:any) => {
                    if (itemsList.has(item.title)) {
                        const currentQty = itemsList.get(item.title)
                        itemsList.set(item.title, currentQty + item.qty)
                    } else {
                        itemsList.set(item.title, item.qty)
                    }
                })
            })

            itemsList.forEach((qty, item) => {
                const roomItem = roomItems
                    .filter((el:any) => el.title === item)
                    .map((el:any) => {
                        return {
                            ...el,
                            qty,
                        }
                    })

                itemsArray.push(roomItem[0])
            })
        }

        // Empty list if user is adding custom
        if (customList && !customListItems.length) {
            itemsArray = []
        }

        // If has custom list
        if (customListItems.length) itemsArray = customListItems


        setItems(itemsArray)
        onItemsChange(itemsArray)
    }, [customListItems, rooms])

    useEffect(() => {
        if (customList) {
            onComplexMethodChange(true)
            onAdditionalsChange && onAdditionalsChange([])
        } else {
            setCustomListItems([])
            onComplexMethodChange(false)
        }
    }, [customList])

    useEffect(() => {
        if (customVolume) {
            onAdditionalsChange && onAdditionalsChange([])
            onComplexMethodChange(false)
            setCustomList(false)
            setCustomListItems([])
            onSetCustomValue(customVolumeNumber)
        } else {
            onSetCustomValue(0)
        }
    }, [customVolume])

    useEffect(() => {
        onSetCustomValue(customVolumeNumber)
    }, [customVolumeNumber])

    const handleListAdd = (newItems: Array<ItemProps>) => {
        setCustomVolume(false)
        setCustomListItems(newItems)
    }

    const handleItemQtyChange = (item: ItemProps, qty: number) => {
        let newItems = JSON.parse(JSON.stringify(items))

        if (!Boolean(qty)) {
            newItems = newItems.filter(
                (el: { title: String }) => el.title !== item.title
            )
        } else {
            const currentItem = newItems.find(
                (el: ItemProps) => el.title === item.title
            )

            currentItem.qty = qty
        }

        setItems(newItems)
        onItemsChange(newItems)
    }

    const handleItemAdditionalsChange = (
        item: ItemProps,
        additionals: Array<string>
    ) => {
        const newItems = JSON.parse(JSON.stringify(items))

        const currentItem = newItems.find(
            (el: ItemProps) => el.title === item.title
        )

        currentItem.additionals = additionals

        setItems(newItems)
        onItemsChange(newItems)
    }

    return (
        <>
            {!showList && (
                <>
                    <CalcGroup>
                        <CalcGroupTitle>Маршрут</CalcGroupTitle>
                        <CalcGroupRowMain cols={2}>
                            <Combobox
                                label="Откуда"
                                value={city}
                                options={cities}
                                onInput={handleCityInput}
                                error={error && !city.length}
                                canReverse
                                onReverse={handleReverse}
                            />

                            <Combobox
                                label="Куда"
                                value={destination}
                                options={cities}
                                onInput={handleDestinationInput}
                                error={error && !destination.length}
                            />
                        </CalcGroupRowMain>

                        <CalcSubGroup>
                            <Checkbox
                                label="Указать объем вещей"
                                onChange={setCustomVolume}
                            />

                            {customVolume && (
                                <>
                                    <WorkplacesInput
                                        type="number"
                                        min={1}
                                        label="Объем вещей"
                                        onInput={setCustomVolumeNumber}
                                        value={customVolumeNumber}
                                    />
                                </>
                            )}
                        </CalcSubGroup>
                    </CalcGroup>

                    <CalcGroup disabled={!city || !destination}>
                        <CalcGroupTitle>
                            Количество кабинетов (объём)
                            <CalcLink
                                onClick={() => !customVolume && handleAddList()}
                                disabled={customVolume || customList}
                            >
                                <img src={plus} alt="" />
                                Добавить список вручную
                            </CalcLink>
                        </CalcGroupTitle>
                        <CalcGroupRow cols={5}>
                            <CheckboxRadio
                                type="checkbox"
                                name="rooms"
                                icon={kitchen}
                                label="Кухня"
                                onChange={handleRooms}
                                hasLink
                                checked={rooms.includes('Кухня')}
                                onClick={() => setShowList(true)}
                                disabled={customVolume || customList}
                            />
                            <CheckboxRadio
                                type="checkbox"
                                name="rooms"
                                icon={livingroom}
                                label="Гостинная"
                                onChange={handleRooms}
                                hasLink
                                checked={rooms.includes('Гостинная')}
                                onClick={() => setShowList(true)}
                                disabled={customVolume || customList}
                            />
                            <CheckboxRadio
                                type="checkbox"
                                name="rooms"
                                icon={bedroom}
                                label="Спальня"
                                onChange={handleRooms}
                                hasLink
                                checked={rooms.includes('Спальня')}
                                onClick={() => setShowList(true)}
                                disabled={customVolume || customList}
                            />
                            <CheckboxRadio
                                type="checkbox"
                                name="rooms"
                                icon={childroom}
                                label="Детская"
                                onChange={handleRooms}
                                hasLink
                                checked={rooms.includes('Детская')}
                                onClick={() => setShowList(true)}
                                disabled={customVolume || customList}
                            />
                            <CheckboxRadio
                                type="checkbox"
                                name="rooms"
                                icon={bathroom}
                                label="Ванная"
                                onChange={handleRooms}
                                hasLink
                                checked={rooms.includes('Ванная')}
                                onClick={() => setShowList(true)}
                                disabled={customVolume || customList}
                            />
                            {customList && (
                                <CheckboxRadio
                                    type="checkbox"
                                    name="rooms"
                                    label="Свой список"
                                    onChange={() => setCustomList(false)}
                                    hasLink
                                    checked={customList}
                                    onClick={() => setShowList(true)}
                                />
                            )}
                        </CalcGroupRow>
                    </CalcGroup>

                    <CalcGroup disabled={!city || !destination}>
                        <CalcGroupTitle>Дополнительные услуги</CalcGroupTitle>
                        <CalcGroupRowWrap>
                            <CalcGroupRow cols={2}>
                                <CheckboxBtn
                                    name="additional"
                                    label="Разобрать"
                                    onChange={handleAdditionals}
                                    disabled={customVolume || customList}
                                    checked={additionals.includes('Разобрать')}
                                />
                                <CheckboxBtn
                                    name="additional"
                                    label="Упаковать"
                                    onChange={handleAdditionals}
                                    disabled={customVolume || customList}
                                    checked={additionals.includes('Упаковать')}
                                />
                                <CheckboxBtn
                                    name="additional"
                                    label="Собрать"
                                    onChange={handleAdditionals}
                                    disabled={customVolume || customList}
                                    checked={additionals.includes('Собрать')}
                                />
                                <CheckboxBtn
                                    name="additional"
                                    label="Распаковать"
                                    onChange={handleAdditionals}
                                    disabled={customVolume || customList}
                                    checked={additionals.includes(
                                        'Распаковать'
                                    )}
                                />
                            </CalcGroupRow>
                            <CalcGroupRow cols={2}>
                                <CheckboxBtn
                                    name="additional"
                                    label="Загрузить"
                                    onChange={handleAdditionals}
                                    checked={additionals.includes('Загрузить')}
                                />
                                <Switchbox
                                    name="additional"
                                    label="Лифт загрузки"
                                    onChange={handleAdditionals}
                                    checked={additionals.includes(
                                        'Лифт загрузки'
                                    )}
                                    disabled={
                                        !additionals.includes('Загрузить')
                                    }
                                />
                                <CheckboxBtn
                                    name="additional"
                                    label="Выгрузить"
                                    onChange={handleAdditionals}
                                    checked={additionals.includes('Выгрузить')}
                                />
                                <Switchbox
                                    name="additional"
                                    label="Лифт выгрузки"
                                    onChange={handleAdditionals}
                                    checked={additionals.includes(
                                        'Лифт выгрузки'
                                    )}
                                    disabled={
                                        !additionals.includes('Выгрузить')
                                    }
                                />
                            </CalcGroupRow>
                        </CalcGroupRowWrap>
                    </CalcGroup>
                </>
            )}
            {showList && (
                <List
                    simple={customVolume}
                    items={items}
                    onAdd={(newItems: Array<ItemProps>) =>
                        handleListAdd(newItems)
                    }
                    onItemQtyChange={handleItemQtyChange}
                    onItemAdditionalsChange={handleItemAdditionalsChange}
                    onCancel={() => setShowList(false)}
                    onListClose={() => setShowList(false)}
                />
            )}
        </>
    )
}

export default Home
