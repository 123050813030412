import { useState } from 'react'
import styled from 'styled-components'

import CheckboxBtn from '../../form/CheckboxBtn'

import plus from '../../../assets/img/plus.svg'
import minus from '../../../assets/img/minus.svg'
import { ItemProps } from '../../../utility/calc'

const Wrap = styled.li`
    padding: 16px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    align-items: center;

    @media (max-width: 680px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const ItemHead = styled.div`
    display: flex;
    align-items: center;
`

const ItemTitle = styled.div`
    font-size: 14px;
    margin-right: 16px;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`

const ItemSize = styled.div`
    color: #808080;
    font-weight: 300;
    font-size: 14px;

    @media (max-width: 480px) {
        font-size: 12px;
    }
`

const ItemServices = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 400px;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
`

const ItemQty = styled.div`
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    display: flex;
    margin-left: auto;
    padding: 14px 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }

    @media (max-width: 680px) {
        margin: 0;
        margin-top: 10px;
    }
`

const ItemQtyBtn = styled.button`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    cursor: pointer;
`

const ItemQtyControl = styled.div`
    width: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`

const ServicesBtn = styled(CheckboxBtn)`
    label {
        font-size: 12px;
        padding: 4px;
        background: #f5f5f5;
        border-radius: 4px;
    }
`

interface ListItemProps {
    simple?: boolean
    item: ItemProps
    onQtyChange: Function
    onAdditionalsChange: Function
}

const ListItem = ({
    item,
    simple,
    onQtyChange,
    onAdditionalsChange,
}: ListItemProps) => {
    const handleAdditionals = (val: string) => {
        let newAdditionals: Array<string>
        if (item.additionals.includes(val)) {
            newAdditionals = item.additionals.filter((el) => el === val)
        } else {
            newAdditionals = [...item.additionals]
            newAdditionals.push(val)
        }
        onAdditionalsChange(newAdditionals)
    }

    const handleQtyChange = (qty: number) => {
        onQtyChange(qty)
    }

    return (
        <Wrap>
            <div>
                <ItemHead>
                    <ItemTitle>{item.title}</ItemTitle>
                    {item.size && <ItemSize>{item.size}</ItemSize>}
                </ItemHead>
                {!simple && (
                    <ItemServices>
                        <ServicesBtn
                            name="additional"
                            label="Разобрать"
                            onChange={handleAdditionals}
                            checked={item.additionals.includes('Разобрать')}
                        />
                        <ServicesBtn
                            name="additional"
                            label="Собрать"
                            onChange={handleAdditionals}
                            checked={item.additionals.includes('Собрать')}
                        />
                        <ServicesBtn
                            name="additional"
                            label="Упаковать"
                            onChange={handleAdditionals}
                            checked={item.additionals.includes('Упаковать')}
                        />
                        <ServicesBtn
                            name="additional"
                            label="Распаковать"
                            onChange={handleAdditionals}
                            checked={item.additionals.includes('Распаковать')}
                        />
                    </ItemServices>
                )}
            </div>

            <ItemQty>
                {!simple && (
                    <ItemQtyBtn
                        onClick={() =>
                            item.qty > 0 && handleQtyChange(item.qty - 1)
                        }
                    >
                        <img src={minus} alt="" />
                    </ItemQtyBtn>
                )}
                <ItemQtyControl>{item.qty}</ItemQtyControl>
                {!simple && (
                    <ItemQtyBtn onClick={() => handleQtyChange(item.qty + 1)}>
                        <img src={plus} alt="" />
                    </ItemQtyBtn>
                )}
            </ItemQty>
        </Wrap>
    )
}

export default ListItem
